<template>
  <div class="aboutUsBox">
    <!-- <div class="main" v-title data-title="2131231"></div> -->
    <div>
      <div class="box_top" v-if="container.coverPic">
        <img :src="container.coverPic" alt=""/>
      </div>
      <div class="box_bottom" v-if="container.length !== 0">
        <!-- 这一块是获取的内容 -->
        <div v-html="container.content"></div>
      </div>
      <div v-if="noData">
        <lostPage></lostPage>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../assets/axios/axios";
import lostPage from "../components/common/404/404";
import {mapState} from "vuex";

export default {
  components: {
    lostPage,
  },
  data() {
    return {
      noData: false,
      p: null,
    };
  },
  computed: {
    ...mapState(["langData", "container"]),
  },
  created() {
    this.getPage();
    this.getReferer();
  },
  methods: {
    getPage() {
      var path = this.$route.params.slug;

      axios.getSlug(path, this.p).then((res) => {
        const {data} = res.data;
        if (!data) {
          this.noData = true;
          return;
        }
        this.$store.commit("getContainer", data);
      });
    },
    getReferer() {
      this.p = this.$route.query.p || "";
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.aboutUsBox {
  // 盒子顶部
  .box_top {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;

    // height: 26.875rem;
    img {
      width: 100%;
    }
  }

  // 盒子底部
  .box_bottom {
    width: 80%;
    margin: 0 auto;
    padding: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .box_bottom {
    width: 90% !important;
    margin: 0 auto;
    padding: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .box_bottom {
    width: 100% !important;
    margin: 0 auto;
    padding: 2rem;
  }
}
</style>
